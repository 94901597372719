@keyframes rainbow {
  0%   {fill: red;}
  20%  {fill: yellow;}
  40%  {fill: blue;}
  60% {fill: rgb(104, 216, 244);}
  80% {fill: green;}
  100%   {fill: red;}
}


svg.loading-logo {
  animation-name: rainbow;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}